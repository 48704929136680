import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, notification } from "antd";
import "./style.css";
import sealogo from "../../assets/home-icons/sealogin.PNG";
import { getLoginDetails } from "../../api/landing";
import { showLoader, hideLoader } from "../../duck/actions/commonActions";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Login Notification",
      description: type === 'success' ? 
        "You have successfully logged in." : 
        "There was an error logging in. Please try again.",
    });
  };

  const onFinish = async (values) => {
    console.log("values", values);
    try {
      dispatch(showLoader());
      const payload = {
        password: values?.password,
        username: values?.username,
      };
      const res = await getLoginDetails(payload);
      console.log("Success:", res);
      if (res?.status === 201) {
        openNotificationWithIcon('success');
        navigate("/home");
      } else {
        openNotificationWithIcon('error');
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      openNotificationWithIcon('error');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      {contextHolder}
      <div className="top-header">
        <img src={sealogo} alt="Logo" />
      </div>
      <Form
        layout="vertical"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="login-wrapper"
      >
        <Form.Item>
          <span id="header">Please Enter Your Information</span>
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please enter your username!",
            },
          ]}
          className="username"
        >
          <Input id="username" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
          className="password"
        >
          <Input.Password id="password" />
        </Form.Item>

        <Form.Item>
          <div tabIndex="0">
            <Button type="primary" htmlType="submit" id="login-btn">
              Login
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
