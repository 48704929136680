import {
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  GET_E2EMFDATA_START,
  GET_E2EMFDATA_SUCCESS,
  GET_E2EMFDATA_FAILED,

  GET_CANADALEVEL2DATA_START,
  GET_CANADALEVEL2DATA_SUCCESS,
  GET_CANADALEVEL2DATA_FAILED,
} from "../types/types";

import { getE2EMFData,getCanadaLevel2Data } from "../../api/landing";
export const showLoader = () => (dispatch) => {
  dispatch({
    type: SHOW_LOADING,
    payload: true,
  });
};

export const hideLoader = () => (dispatch) => {
  dispatch({
    type: HIDE_LOADING,
    payload: false,
  });
};

export const showNotification = (type, message, description) => (dispatch) => {
  dispatch({
    type: SHOW_NOTIFICATION,
    payload: {
      type,
      message,
      description,
      status: true,
    },
  });
};

export const hideNotification = () => (dispatch) => {
  dispatch({
    type: HIDE_NOTIFICATION,
    payload: {
      status: false,
    },
  });
};

export const getE2EMFFlowData = (values)=> async (dispatch,getState)=>{
  try{
    // Dispatch an action to indicate the start of the request
    dispatch({type:GET_E2EMFDATA_START})

    const response = await getE2EMFData(values)
    dispatch(
      {
        type:GET_E2EMFDATA_SUCCESS,
        payload:response.data
      });
  }
  catch(error){
   dispatch(
    {
      type:GET_E2EMFDATA_FAILED,
      payload: error.message?error.message:"Server Error"
    }
   )

  }
}

export const getCanadaL2Data = (values)=> async (dispatch,getState)=>{
  try{
    // Dispatch an action to indicate the start of the request
    dispatch({type:GET_CANADALEVEL2DATA_START})

    const response = await getCanadaLevel2Data(values)
    dispatch(
      {
        type:GET_CANADALEVEL2DATA_SUCCESS,
        payload:response.data
      });
  }
  catch(error){
   dispatch(
    {
      type:GET_CANADALEVEL2DATA_FAILED,
      payload: error.message?error.message:"Server Error"
    }
   )

  }
}