import { AjaxService } from "../utils/AjaxService";
import {API_CASSA} from "../constants/apiBaseUrl"
import { API_SEA } from "../constants/apiBaseUrl";



export const getE2EMFData = (_queryParam) => {
  return AjaxService.post(
    `${API_SEA}/material-flow/api/materialflow/getE2EMF`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getCanadaLevel2Data = (_queryParam) => {
  return AjaxService.post(
    `${API_SEA}/material-flow/api/materialflow/getCanadaLevel2MaterialView/`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getCanadaLevel3Data = (_queryParam) => {
  return AjaxService.post(
    `${API_SEA}/material-flow/api/materialflow/getCanadaLevel3MaterialView/`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getGoodsHistroyData = (_queryParam) => {
  return AjaxService.post(
    `${API_SEA}/material-flow/api/materialflow/getGoodsHistroy/`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


export const getLoginDetails = (_queryParam) => {
  return AjaxService.post(
    `${API_SEA}/sea-auth/signin`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};