import React, { useState, useEffect } from "react";
import { Card, Table } from "antd";
import Accordian from "../../../atoms/accordians/accordian";
import "./itemdetails.css";

const ItemDetails = ({ canada3data }) => {
  const [data, setData] = useState([]);
  const [showItem, setShowItem] = useState(true);
  const [total, setTotal] = useState(0); // State to hold the total

  useEffect(() => {
    // Update total whenever data changes
    calculateTotal();
  }, [data]);

  useEffect(() => {
    setShowItem(true);
    setData(canada3data);
  }, [canada3data]); // Trigger effect when canada3data changes

  const calculateTotal = () => {
    let newTotal = 0;
    data.forEach((item) => {
      newTotal += parseFloat(item.netwr); // Ensure netwr is treated as a number
    });
    setTotal(Math.round(newTotal)); // Update total state
  };

  // Render the total row dynamically
  const totalRow = {
    key: "total",
    ebelp: <div className="text-color">Total</div>,
    ekpo_matnr: "",
    maktx: "",
    poType: "",
    ekpo_menge: "",
    lmein: "",
    unit_net_per: "",
    netwr: <div className="text-color">{total}</div>, // Use the total from state
  };

  const columns = [
    {
      title: "Line Item",
      dataIndex: "ebelp",
    },
    {
      title: "Material",
      dataIndex: "ekpo_matnr",
    },
    {
      title: "Material Description",
      dataIndex: "maktx",
    },
    {
      title: "PO Type",
      dataIndex: "poType",
    },
    {
      title: "Qty",
      dataIndex: "ekpo_menge",
    },
    {
      title: "Uom",
      dataIndex: "lmein",
    },
    {
      title: "Unit Net Per",
      dataIndex: "unit_net_per",
    },
    {
      title: "Total Net Price",
      dataIndex: "netwr",
    },
  ];

  return (
    <div>
      <Card
        title={
          <div className="card-title">
            <span>Item Details</span>
            <span>
              <Accordian showContent={showItem} setShowContent={setShowItem} />
            </span>
          </div>
        }
        size="small"
        className="item-card"
        bodyStyle={{ padding: "0px" }}
      >
        {showItem && (
          <Table
            size="small"
            columns={columns}
            dataSource={[...data, totalRow]} // Include the total row in the dataSource
            pagination={false}
            className="item-table"
          />
        )}
      </Card>
    </div>
  );
};

export default ItemDetails;
