export const listview_data = [
  {
    key: "1",
    purchase_order: "0921065489",
    vendor_name: "UN KOMOBO",
    vendor_id: "0001017124",
    plant: 4000,
    plant_name: "Fagmsam z Fagmsam Imk",
    net_value: 3600000,
  },
  {
    key: "2",
    purchase_order: "0921065480",
    vendor_name: "UN KOMOBO",
    vendor_id: "00010171242",
    plant: 4000,
    plant_name: "Fagmsam z Fagmsam Imk",
    net_value: 3500000,
  },
  {
    key: "3",
    purchase_order: "0921065480",
    vendor_name: "UN KOMOBO",
    vendor_id: "0001017124",
    plant: 4000,
    plant_name: "Fagmsam z Fagmsam Imk",
    net_value: 3500000,
  },
  {
    key: "4",
    purchase_order: "0921065480",
    vendor_name: "UN KOMOBO",
    vendor_id: "0001017124",
    plant: 3000,
    plant_name: "Fagmsam z Fagmsam Imk",
    net_value: 3500000,
  },
  {
    key: "5",
    purchase_order: "0921065480",
    vendor_name: "UN KOMOBO",
    vendor_id: "0001017124",
    plant: 4000,
    plant_name: "Fagmsam z Fagmsam Imk",
    net_value: 3500000,
  },
  {
    key: "6",
    purchase_order: "0921065480",
    vendor_name: "UN KOMOBO",
    vendor_id: "0001017124",
    plant: 4000,
    plant_name: "Fagmsam z Fagmsam Imk",
    net_value: 3500000,
  },
  {
    key: "7",
    purchase_order: "0921065480",
    vendor_name: "UN KOMOBO",
    vendor_id: "0001017124",
    plant: 4000,
    plant_name: "Fagmsam z Fagmsam Imk",
    net_value: 3500000,
  },
];

export const purchasedetail_data = [
  {
    key: "1",
    po_creation: "12/04/2021",
    terms: "",
    buyer_name: "4000",
    po_change: "",
    freight_terms: "",
    buyer_phone: "",
    payment_terms: "ZB32",
    buyer_email: "",
  },
];

export const itemdetails_data = [
  {
    key: "1",
    line_item: "1",
    material: "",
    material_description: "4000",
    po_type: "NORMAL PURCHASING",
    qty: "34436.0",
    uom: "",
    unit_net_per: "1",
    total_net_per: 34436,
  },
  {
    key: "2",
    line_item: "1",
    material: "",
    material_description: "4000",
    po_type: "NORMAL PURCHASING",
    qty: "34436.0",
    uom: "",
    unit_net_per: "1",
    total_net_per: 1,
  },
];

export const goodsmovement_data = [
  {
    key: "1",
    material_document: "0000106330",
    po_history_category: "Q",
    movement_type: "",
    qty: 1.0,
    amount: 9.436,
    currency: "CAD",
    posting_date: "09/26/2012",
  },
  {
    key: "2",
    material_document: "0000106330",
    po_history_category: "Q",
    movement_type: "",
    qty: 1.0,
    amount: 9.436,
    currency: "CAD",
    posting_date: "09/26/2012",
  },
  {
    key: "3",
    material_document: "0000106330",
    po_history_category: "Q",
    movement_type: "",
    qty: 1.0,
    amount: 9.436,
    currency: "CAD",
    posting_date: "09/26/2012",
  },
];
