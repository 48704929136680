import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table, Divider } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { goodsmovement_data } from "../../listview/tabledata";
import Accordian from "../../../atoms/accordians/accordian";
import "./goodsmovement.css";
import { getGoodsHistroyData } from "../../../../api/landing";
import { useDispatch, useSelector } from "react-redux";
import { showLoader, hideLoader } from "../../../../duck/actions/commonActions";
const GoodsMovement = ({ponumber,showGoods, setShowGoods}) => {
  const dispatch = useDispatch();
  // const [showGoods, setShowGoods] = useState(false);
  const [goodsData, setGoodsData] = useState([]);
 
  const columns = [
    {
      title: "Material Document",
      dataIndex: "materialDocument",
      sorter: (a, b) => a.materialDocument - b.materialDocument,
    },
    {
      title: "PO History Category",
      dataIndex: "poHistory",
      sorter: (a, b) => a.poHistory.localeCompare(b.poHistory),
    },
    {
      title: "Movement Type",
      dataIndex: "movementType",
      sorter: (a, b) => a.movementType - b.movementType,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      sorter: (a, b) => a.currency - b.currency,
    },
    {
      title: "Posting Date",
      dataIndex: "postingDate",
      sorter: (a, b) => a.postingDate - b.postingDate,
    },
  ];

  const getGoodsHistroy = async () => {
    try {
      dispatch(showLoader());
      // const payload = {
      //   poNumber: "0000108982",
      //   type: "Movement",
      // };

      const payload = {
        poNumber:ponumber,
        type: "Movement",
      };

      const res = await getGoodsHistroyData(payload);
      setGoodsData(res?.data?.aggregatedGoodsHistoryList);
      console.log("resgood", res?.data?.aggregatedGoodsHistoryList);
      dispatch(hideLoader());
    } catch (error) {
      alert(error);
    }
  };

  const HandleShow = (e) => {
    e.preventDefault();
    setShowGoods(!showGoods);
    getGoodsHistroy();
    return false;
  };

  useEffect(() => {
    // setShowGoods(true);
  }, []);

  console.log("goodsData", goodsData);
  return (
    <>
      <div>
        <Card
          title={
            <div className="card-title">
              <span>Goods Movement</span>
              <span>
                <CaretRightOutlined
                  onClick={(e) => HandleShow(e)}
                  rotate={showGoods ? 90 : 0}
                />
              </span>
            </div>
          }
          size="small"
          className="goods-card"
          bodyStyle={{ padding: "0px" }}
        >
          {showGoods && (
            <Table
              size="small"
              columns={columns}
              dataSource={goodsData}
              pagination={false}
              className="goods-table"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default GoodsMovement;
 // amount: "3280.00";
  // currency: "CAD";
  // materialDocument: "5000814614";
  // movementType: "101";
  // poHistory: "E";
  // postingDate: "2012.03.30";
  // purchasingDocument: "0000108982";
  // quantity: "1000.000";