import React from "react";
import "./header.css";
import logo from "../../../assets/logo/logo.svg";
import download from "../../../assets/header-top-icons/download.svg";
import mail from "../../../assets/header-top-icons/mail.svg";
import notification from "../../../assets/header-top-icons/notification.svg";
import window from "../../../assets/header-top-icons/window.svg";
import logout from "../../../assets/logo/Logout.svg"

import { useNavigate } from "react-router-dom";
const Header = (props) => {
  const navigate = useNavigate();

  const onLogout = () => {

    navigate("/login");
  };
  return (
    <>
      <div className="header-container">
        <div className="logo-container">
          <img src={logo} alt="" style={{ height: "52px", padding: "5px" }} />
        </div>
        <div className="right-navigation-container">
          <ul>
            <li>
              <img src={download} />
            </li>
            <li>
              <img src={mail} />
            </li>
            <li>
              <img src={notification} />
            </li>
            <li>
              <img src={window} />
            </li>
            <li>
              <img className="logout" src={logout} onClick={()=>onLogout()}/>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default Header;
