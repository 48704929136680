import { CONFIG } from "../../../../constants/config";
import InternalIcon from "../../../../assets/home-icons/2.svg";
import ManufactureIcon from "../../../../assets/home-icons/3.svg";
import DistributionIcon from "../../../../assets/home-icons/4.svg";
import CustomerIcon from "../../../../assets/home-icons/5.svg";

let Columns;

switch (CONFIG?.Product) {
  case 'Spectra':
    Columns = [
      { label: "Internal/External Vendor",icon:InternalIcon },
      { label: "Manufacturing Plan",icon:ManufactureIcon },
      { label: "Distribution Plants",icon:DistributionIcon },
      { label: "Customer/Distributor",icon:CustomerIcon }
    ];
    break;
  default:
    Columns = [
        { label: "Internal/External Vendor",icon:InternalIcon },
        { label: "Manufacturing Plan",icon:ManufactureIcon },
        { label: "Distribution Plants",icon:DistributionIcon },
    ];
    break;
}

export { Columns };
