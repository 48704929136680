const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const SET_THEME = 'SET_THEME';


//Spectra
const GET_E2EMFDATA_NOTSTARTED="GET_E2EMFDATA_NOTSTARTED"
const GET_E2EMFDATA_START = "GET_E2EMFDATA_START";
const GET_E2EMFDATA_SUCCESS = "GET_E2EMFDATA_SUCCESS";
const GET_E2EMFDATA_FAILED = "GET_E2EMFDATA_FAILED";

const GET_CANADALEVEL2DATA_NOTSTARTED="GET_CANADALEVEL2DATA_NOTSTARTED"
const GET_CANADALEVEL2DATA_START = "GET_CANADALEVEL2DATA_START";
const GET_CANADALEVEL2DATA_SUCCESS = "GET_CANADALEVEL2DATA_SUCCESS";
const GET_CANADALEVEL2DATA_FAILED = "GET_CANADALEVEL2DATA_FAILED";

export {
    SHOW_LOADING,
    HIDE_LOADING,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SET_THEME,
  
    //Spectra
    GET_E2EMFDATA_NOTSTARTED,
    GET_E2EMFDATA_START,
    GET_E2EMFDATA_SUCCESS,
    GET_E2EMFDATA_FAILED,
    
    GET_CANADALEVEL2DATA_NOTSTARTED,
    GET_CANADALEVEL2DATA_START,
    GET_CANADALEVEL2DATA_SUCCESS,
    GET_CANADALEVEL2DATA_FAILED,
  
}