import React from "react";
import { Spin } from "antd";
 import "./style.css";

const DEFAULT_LOADING_TEXT = "Please wait...";

const Spinner = ({ text }) => {
  return (
    <div className="loading-wrapper">
      <Spin tip="Loading..." fullscreen />
    </div>
  );
};

export default Spinner;
