import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table, Divider } from "antd";
import { purchasedetail_data } from "../../listview/tabledata";
import Accordian from "../../../atoms/accordians/accordian";
import "./purchasedetail.css";

const PurchaseDetails = ({canada3data}) => {

  console.log("canada3data",canada3data)

  const [showPurchase, setShowPurchase] = useState(true);

  const columns = [
    {
      title: "PO Creation",
      dataIndex: "aedat",
    },
    {
      title: "Terms",
      dataIndex: "terms",
    },
    {
      title: "Buyer's Name",
      dataIndex: "ekorg",
    },
    {
      title: "PO Change",
      dataIndex: "po_change",
    },
    {
      title: "Freight Terms",
      dataIndex: "freight_terms",
    },
    {
      title: "Buy's Phone/Fax",
      dataIndex: "buyer_phone",
    },
    {
      title: "Payment Terms",
      dataIndex: "zterm",
    },
    {
      title: "Buy's Email Address",
      dataIndex: "buyer_email",
    },
  ];

  useEffect(() => {
    setShowPurchase(true);
  }, []);

  return (
    <>
      <div>
        {canada3data && canada3data.map((item)=>{
          return (
            <Card
          title={
            <div className="card-title">
              <span>Purchase Details</span>
              <span>
                <Accordian
                  showContent={showPurchase}
                  setShowContent={setShowPurchase}
                />
              </span>
            </div>
          }
          size="small"
          className="purchase-card"
          bodyStyle={{ padding: "0px" }}
        >
          {showPurchase && (
            <div>
              <Row gutter={[16, 16]} justify="space-between">
                <Col span={8} style={{paddingLeft:"8px",paddingRight:"0px"}}>
                  <div className="sub-card-container">
                    <Card
                      title="Vendor Information"
                      size="small"
                      className="sub-card-content"
                    >
                      {item?.lifnr}<br/>
                      {item?.name1} <br/>
                      {item?.lpstlz}<br/>
                      {item?.regio}<br/>
                       {item?.lstras}<br/>
                      {item?.adrnr}<br/>
                    </Card>
                  </div>
                </Col>
                <Col span={8} style={{paddingLeft:"0px",paddingRight:"0px"}}>
                  <div className="sub-card-container">
                    <Card
                      title="Bill to"
                      size="small"
                      className="sub-card-content"
                    >
                      {item?.name2}<br/>
                     {item?.stras}<br/>
                      {item?.pstlz}<br/>
                      {item?.ort01}<br/>
                    </Card>
                  </div>
                </Col>
                <Col span={8} style={{paddingLeft:"0px",paddingRight:"8px"}}>
                  <div className="sub-card-container">
                    <Card
                      title="Ship to"
                      size="small"
                      className="sub-card-content"
                    >
                      {item?.name2}<br/>
                     {item?.stras}<br/>
                      {item?.pstlz}<br/>
                      {item?.ort01}<br/>
                    </Card>
                  </div>
                </Col>
              </Row>

              <Divider></Divider>
              <Table
                size="small"
                columns={columns}
                dataSource={canada3data}
                pagination={false}
                className="purchase-table"
              />
            </div>
          )}
        </Card>
          )
        })}
      </div>
    </>
  );
};

export default PurchaseDetails;
