import React, { useState, useEffect } from "react";
import { Breadcrumb, Layout, Input } from "antd";
import "./home.css";
import flowDiagram from "../../../assets/sidebar-icons/flow-diagram.svg";
import Flowchart from "../../molecules/flowchart/flowchart";
import ListViewNode from "../../molecules/listview/listview";
import { listview_data } from "../../molecules/listview/tabledata";
import { SearchOutlined } from "@ant-design/icons";
import {
  getAllRisks,
  getAllData,
  getAllCompoundList,
} from "../../../api/landing";
import Loading from "../../atoms/Loader";
import { useDispatch, useSelector } from "react-redux";
import { loadState } from "../../../duck/reducers/commonReducer";
import { showLoader, hideLoader } from "../../../duck/actions/commonActions";
import Header from "../../organisms/header/header";
import Sidebar from "../../organisms/sidebar/sidebar";


const { Content } = Layout;
const { Search } = Input;

function Home() {
  const dispatch = useDispatch();

  const showLoading = useSelector((state) => state.commonReducer.showLoading);
  const { getcanadalevel2data, getcanadalevel2dataloadstate } = useSelector(
    (state) => state.commonReducer
  );

  console.log(
    "getcanadalevel2data,getcanadalevel2dataloadstate",
    getcanadalevel2data,
    getcanadalevel2dataloadstate
  );
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeVisiability, setSelectedNodeVisiability] = useState(false);
  const [selectedEdgeVisiability, setSelectedEdgeVisiability] = useState(false);
  const [type, setType] = useState("");
  const [source, setSource] = useState("");

  const [listviewNodeData, setlistviewNodeData] = useState([]);
  const [keyName, setKeyName] = useState("");
  const [ColumnData, setColumnData] = useState([]);

  //for search function
  const [searchValue, setSearchValue] = useState([...listview_data]);
  const [filteredData, setFiltereddata] = useState([]);

  const handleSearch = (e) => {
    // console.log("searching", e?.target?.value);
    const searchTerm = e?.target?.value?.trim().toLowerCase();

    if (searchTerm.length > 0) {
      const filtered = searchValue.filter((entry) =>
        Object.values(entry).some((val) => {
          if (val === null || val === undefined) {
            return false;
          } else {
            return val.toString().toLowerCase().includes(searchTerm);
          }
        })
      );
      setFiltereddata(filtered); // Update the filtered data state
    } else {
      setFiltereddata([]); // Clear the filtered data if the search term is empty
    }
  };

  function findKeyWithData() {
    for (const key in getcanadalevel2data) {
      if (getcanadalevel2data[key].length > 0) {
        setKeyName(key);
        console.log("key", key);
        return getcanadalevel2data[key];
      }
    }
  }

  const getCanadaLevel2Response = () => {
    if (getcanadalevel2dataloadstate === loadState?.STARTED) {
      dispatch(showLoader());
    } else if (getcanadalevel2dataloadstate === loadState?.SUCCESS) {
      // for(const key in getcanadalevel2data){
      //   if(getcanadalevel2data[key].length > 0){
      //       console.log("res",getcanadalevel2data[key])
      //   }

      // }
      const data = findKeyWithData(); //which key has the data
      setlistviewNodeData(data);
      setSearchValue(data);
      dispatch(hideLoader());
    } else if (getcanadalevel2dataloadstate === loadState?.FAILED) {
      dispatch(hideLoader());
      alert("Error in fetching data");
    }
  };

  useEffect(() => {
    getCanadaLevel2Response();
  }, [getcanadalevel2dataloadstate]);

  function ChildComponent(source, type) {
    console.log("ChildComponentExpiry", source, type);
    setSource(source);
    setType(type);
  }
  const squareStyle = {
    display: "inline-block",
    width: "10px",
    height: "10px",
    backgroundColor: "#B65823",
  };
  console.log("listviewNodeData", listviewNodeData);
  return (
    <>
   
   <Layout style={{ minHeight: "100vh" }}>
        <Sidebar />
        <Layout>

    <Header style={{ padding: 0 }} />
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Flow Diagram</Breadcrumb.Item>
        </Breadcrumb>
        <div className="content">
          <div className="home-header">
            <div className="left-header">
              <img src={flowDiagram} />{" "}
              <h3>Supply Chain - Business Insights</h3>
            </div>
            <div className="right-header"></div>
          </div>

          <Flowchart
            setSelectedNode={setSelectedNode}
            setSelectedNodeVisiability={setSelectedNodeVisiability}
            setSelectedEdgeVisiability={setSelectedEdgeVisiability}
            ChildComponent={ChildComponent}
          />

          <div className="legends-main">
            <div className="legend-div">
              <span style={{ color: "#3B2B64", fontWeight: "bold" }}>
                Nodes:
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#B65823" }}
                ></span>
                Internal Vendor
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#2A4F64" }}
                ></span>
                External Vendor
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#50347E" }}
                ></span>
                Manufacturing Plan
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#226A5E" }}
                ></span>
                Distribution Plants
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#11309B" }}
                ></span>
                Customer/Distributor
              </span>{" "}
            </div>

            <div className="legend-div">
              <span style={{ color: "#3B2B64", fontWeight: "bold" }}>
                Links:
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#FFBDF2" }}
                ></span>
                Consignment
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#D1F39E" }}
                ></span>
                Sub Consignment
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#7BCEBA" }}
                ></span>
                Direct Sales
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#D9C435" }}
                ></span>
                External Processing
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#4FA0BD" }}
                ></span>
                Normal Purchasing
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#E864A4" }}
                ></span>
                Drop Ship
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#EEB791" }}
                ></span>
                Intercompany Drop Ship
              </span>
              <span className="legend-span">
                <span
                  className="legends"
                  style={{ backgroundColor: "#81F214" }}
                ></span>
                STO
              </span>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="home-header">
            <div className="left-header">
              <img src={flowDiagram} /> <h3>List Views</h3>
            </div>
            <div className="right-header"></div>
          </div>
          <Input
            placeholder="Search here"
            //value={serchVale}
            onChange={(e) => handleSearch(e)}
            className="add-search"
            prefix={<SearchOutlined />}
          />

          {(selectedNodeVisiability || selectedEdgeVisiability) && (
            <ListViewNode
              dataSource={
                filteredData.length > 0 ? filteredData : listviewNodeData
              }
              keyName={keyName}
              source={source}
              type={type}
            />
          )}
        </div>
      </Content>
      </Layout>
      </Layout>
    </>
  );
}

export default Home;
// const api_call = async () => {
//   try {
//     let response = await getAllData();
//     //  console.log("getall",response)
//   } catch (error) {
//     alert("Error in fetching Data");
//   }
// };
// useEffect(() => {
//   api_call();
// }, []);
{
  /* {selectedNodeVisiability && (
            <ListViewNode
              dataSource={filteredData.length > 0 ? filteredData : dataSource}
            />
          )}
           {selectedEdgeVisiability && (
            <ListViewNode
              dataSource={""}
            />
          )} */
}
