import {
  SHOW_LOADING,
  HIDE_LOADING,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  GET_E2EMFDATA_NOTSTARTED,
  GET_E2EMFDATA_START,
  GET_E2EMFDATA_SUCCESS,
  GET_E2EMFDATA_FAILED,
  GET_CANADALEVEL2DATA_NOTSTARTED,
  GET_CANADALEVEL2DATA_START,
  GET_CANADALEVEL2DATA_SUCCESS,
  GET_CANADALEVEL2DATA_FAILED,
} from "../types/types";

export const loadState = {
  NOTSTARTED: 'NOTSTARTED',
  STARTED: 'STARTED',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const initialState = {
  showLoading: false,
  notification: {
    type: "",
    message: "",
    description: "",
    status: false,
  },
  error:null,
  gete2emflowdata:{},
  gete2emflowdataLoadState:loadState.NOTSTARTED,
  getcanadalevel2dataloadstate:loadState.NOTSTARTED,
  getcanadalevel2data:{},
};

export default (state = initialState, action) => {

  switch (action.type) {
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case HIDE_LOADING:
      return {
        ...state,
        showLoading: action.payload,
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          status: true,
          ...action.payload,
        },
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          status: false,
        },
      };
    case GET_E2EMFDATA_NOTSTARTED:
      return{
        ...state,
        gete2emflowdataLoadState:loadState.NOTSTARTED
      }
    case GET_E2EMFDATA_START:
      return{
        ...state,
        gete2emflowdataLoadState:loadState.STARTED,
        error:null
      }
    case GET_E2EMFDATA_SUCCESS:
      return{
        ...state,
        gete2emflowdata:action.payload,
        gete2emflowdataLoadState:loadState.SUCCESS,
        error:null
      }
    case GET_E2EMFDATA_FAILED:
      return {
        ...state,
        gete2emflowdata: [],
        gete2emflowdataLoadState:loadState.FAILED,
        error:action.payload
      }
    case GET_CANADALEVEL2DATA_NOTSTARTED:
      return {
        ...state,
        getcanadalevel2dataloadstate:loadState.NOTSTARTED
      }
    case GET_CANADALEVEL2DATA_START:
      return{
        ...state,
        getcanadalevel2dataloadstate:loadState.STARTED,
        error:null
      }
    case GET_CANADALEVEL2DATA_SUCCESS:
      return{
        ...state,
        getcanadalevel2data:action.payload,
        getcanadalevel2dataloadstate:loadState.SUCCESS,
        error:null
      }
    case GET_CANADALEVEL2DATA_FAILED:
      return{
        ...state,
        getcanadalevel2data:{},
        getcanadalevel2dataloadstate:loadState.FAILED,
        error:action.payload
      }
    default:
      return state;
  }
};

