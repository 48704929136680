import React, { useState, useCallback, useEffect } from "react";
import "reactflow/dist/style.css";
import "./flowchart.css";
import { Card, Select, Row, Button, Divider } from "antd";
import FlowchartCard from "./flowchartCard";
import { Radio, Tabs } from "antd";
import { useDispatch } from "react-redux";
import { getE2EMFFlowData } from "../../../duck/actions/commonActions";


const { Option } = Select;
function Flowchart({ setSelectedNode, setSelectedNodeVisiability ,setSelectedEdgeVisiability, ChildComponent}) {
  const dispatch = useDispatch();

  const [selectedSource, setSelectedSource] = useState("SAP");
  const [selectedtype, setSelectedType] = useState("netvalue");
  const [mode, setMode] = useState("netvalue");

  const handleSource = (value) => {
    console.log(value);
    setSelectedSource(value);
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
    setSelectedType(e.target.value)
  };

  const getE2EMF_API_CALL =()=>{
    const payload = {
      source: selectedSource,
      mfviewtype: selectedtype,
      // "serviceName": "getE2EMF"
    };
    dispatch(getE2EMFFlowData(payload));
  }

  useEffect(()=>{
    getE2EMF_API_CALL()
    ChildComponent(selectedSource,selectedtype)
  },[dispatch,selectedSource, selectedtype])

  return (
    <>
      <div className="main-div">
        <div className="select-div">
          <Select
            placeholder="Sap"
            style={{ width: 100 }}
            onChange={(e) => handleSource(e)}
          >
            <Option value="SAP">SAP</Option>
            <Option value="GP">GP</Option>
            <Option value="JDE">JDE</Option>
          </Select>

          <Radio.Group
            onChange={handleModeChange}
            value={mode}
            style={{
              marginBottom: 8,
            }}
          >
            <Radio.Button value="netvalue"><span className="radio-span">Net Value</span></Radio.Button>
            <Radio.Button value="materialcount"><span className="radio-span">Material Count</span></Radio.Button>
          </Radio.Group>
        </div>
        <FlowchartCard
          setSelectedNode={setSelectedNode}
          setSelectedNodeVisiability={setSelectedNodeVisiability}
          setSelectedEdgeVisiability={setSelectedEdgeVisiability}
          selectedSource={selectedSource}
          selectedtype={selectedtype}
        />
      </div>
    </>
  );
}

export default Flowchart;
