import React, { useState } from "react";
import  flowDiagram from "../../../assets/sidebar-icons/flow-diagram.svg";
import  search from "../../../assets/sidebar-icons/search.svg";
import  gpt from "../../../assets/sidebar-icons/gpt.svg";
import  bom from "../../../assets/sidebar-icons/bom.svg";
import  pde from "../../../assets/sidebar-icons/pde.svg";
import { Layout, Menu } from "antd";
import "./sidebar.css";
const {Sider } = Layout;

const Sidebar = (props)=>{
    const [collapsed, setCollapsed] = useState(true);

    function getItem(
        label,
        key,
        icon,
        children,
      ) {
        return {
          key,
          icon,
          children,
          label,
        };
      }

    const items= [
        getItem('Flow Diagram', '1', <img src={flowDiagram} />),
        getItem('Search', '2', <img src={search} />),
        getItem('GPT', 'sub1', <img src={gpt} />, [
          getItem('Menu 1', '3'),
          getItem('Menu 2', '4'),
          getItem('Menu 3', '5'),
        ]),
        getItem('BOM Traceability', 'sub2', <img src={bom} />, [getItem('Menu 1', '6'), getItem('Menu 2', '7')]),
        getItem('PDE', '8', <img src={pde} />),
      ];

    return <Sider
    collapsible
    collapsed={collapsed}
    onCollapse={(value) => setCollapsed(value)}
    id="siderContainer"
  >
    <div className="demo-logo-vertical" />
    <Menu
      theme="dark"
      defaultSelectedKeys={["1"]}
      mode="inline"
      items={items}
    />
  </Sider>
}

export default Sidebar;