import React from "react";
import "./App.css";
import { Route, Routes,Navigate } from "react-router-dom";
import Home from "./components/pages/home/home";
import Login from "./components/login";
import Sidebar from "./components/organisms/sidebar/sidebar";
import { Layout } from "antd";
import { Footer } from "antd/es/layout/layout";
import Header from "./components/organisms/header/header";
import Loading from "./components/atoms/Loader";
import { useSelector } from "react-redux";

function App() {
  const showLoading = useSelector((state) => state.commonReducer.showLoading);
  return (
    <>
    <Loading  show={showLoading}/>
      <Layout style={{ minHeight: "100vh" }}>
        {/* <Sidebar /> */}
        <Layout>
         
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" />} />
            {/* <Route path="blogs" element={<Home />} />
            <Route path="contact" element={<Home />} />
            <Route path="*" element={<Home />} /> */}
          </Routes>
          <Routes>
    </Routes>
          {/* <Footer /> */}
        </Layout>
      </Layout>
    </>
  );
}

export default App;
