// nodesData.js
//increasing the node y-axis by 50


export const initialNodes2 =[
  {
    id: "0002002835",
    data: {
      label: (
        <div>
          <p style={{ margin: "0px" }}>0002002835</p>
          <p
            style={{
              background: "#B65823",
              margin: "0px",
              color: "white",
              minHeight: "10px",
            }}
          ></p>
        </div>
      ),
    },
    position: { x: 0, y: 0 },
    type: "input",
    sourcePosition: "right",
    style: {
      border: "1px solid #B65823",
      padding: "0px",
      borderRadius: "5px",

      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      width: "100px",
      color: "#B65823",
      fontWeight: "bold",
    },
  },
  {
    id: "CC-50001",
    data: {
      label: (
        <div>
          <p style={{ margin: "0px" }}>CC-50001</p>
          <p
            style={{
              background: "#B65823",
              margin: "0px",
              color: "white",
              minHeight: "10px",
            }}
          ></p>
        </div>
      ),
    },
    position: { x: 0, y: 50 },
    sourcePosition: "right",
    style: {
      border: "1px solid #B65823",
      padding: "0px",
      borderRadius: "5px",

      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      width: "100px",
      color: "#B65823",
      fontWeight: "bold",
    },
  },
  {
    id: "CC-5000", //h1
    data: {
      label: (
        <div>
          <p style={{ margin: "0px" }}>CC-5000</p>
          <p
            style={{
              background: "#50347E",
              margin: "0px",
              color: "white",
              minHeight: "70px",
            }}
          >
            Fagmsam Fagmsam lmk{" "}
          </p>
        </div>
      ),
    },
    position: { x: 400, y: 100 },
    targetPosition: "left",
    sourcePosition: "right",
    style: {
      border: "1px solid #50347E",
      padding: "0px",
      borderRadius: "5px",

      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      width: "100px",
      color: "#50347E",
      fontWeight: "bold",
    },
  },
]


// export const api_response =  {
//     "nodes": [
//       {
         
//         "stack": "1",
//         "color": "#4c1362",
//         "name": "0000650001",
//         "text": "FOMSSEM Pz B IPELOMB"
//     },
//     {
  
//       "stack": "2",
//       "color": "#1e2548",
//       "name": "1000",
//       "text": "KK-5000"
//   },
  
//     ],
//     "links": [
//       {
//           "targetname": "Fomssem Imk",
//           "color": "#71afdb",
//           "sourcename": "KILOH HNDG IMTEPMOTIAMOL (KOB) (765",
//           "source": "0000650001",
//           "text": "FINISHED GOODS - NORMAL PURCHASING",
//           "value": "2.9377775333549805E9",
//           "target": "1000"
//       },
     
     
//     ]
    
// }

export const api_response =  {
  "nodes": [
  
    {
      "stack": "3",
      "color": "#1e2548",
      "name": "CC-5000",
      "text": "KK-5000"
  },
      {
          "stack": "3",
          "color": "#1e2548",
          "name": "CC-5000",
          "text": "KK-5000"
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0002002835",
          "text": "FOMSSEM Pz B IPELOMB"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000000309",
          "text": "CAGL z JPISKG LTB (KAMKAPB)"
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0001017124",
          "text": "UN KOMOBO"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000000329",
          "text": "SGARREPS BPUH NOPT KAPR."
      },
      {
          "stack": "2",
          "color": "#1a6f8b",
          "name": "1000",
          "text": "Fomssem Imk"
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0002000450",
          "text": "MAWO ROKC LTB."
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0001009128",
          "text": "FUDILOMT GALLISTEPSTIEP HEMEPOL NOM"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000001370",
          "text": "NKCESSAM RGOPN. (WKP)"
      },
      {
          "stack": "1",
          "color": "#eee",
          "name": "0000650262",
          "text": "NKMEIL OD (GELSIMHDAPH)"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000001694",
          "text": "NKCESSAM RGOPN. (DPON)"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000001298",
          "text": "LADLOVS IMK."
      },
      {
          "stack": "3",
          "color": "#1e2548",
          "name": "IP-2300",
          "text": "N. K. G. - Loktoib Em,yne amly"
      },
      {
          "stack": "3",
          "color": "#1e2548",
          "name": "IP-4000",
          "text": "Fagmsam z Fagmsam Imk."
      },
      {
          "stack": "1",
          "color": "#eee",
          "name": "0000650006",
          "text": "FOMSSEM RGOPN. DELHIUN (220) KOB"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000000018",
          "text": "NKCESSAM RGOPN. (NTL)"
      },
      {
          "stack": "1",
          "color": "#eee",
          "name": "0000650001",
          "text": "KILOH HNDG IMTEPMOTIAMOL (KOB) (765"
      },
      {
          "stack": "2",
          "color": "#1a6f8b",
          "name": "4000",
          "text": "Fagmsam z Fagmsam Imk."
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000001607",
          "text": "ROPNOLOT KOMOBO"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000001606",
          "text": "ROPNOLOT KOMOBO"
      },
      {
          "stack": "2",
          "color": "#1a6f8b",
          "name": "2100",
          "text": "N. K. G. - Stodility Testimh"
      },
      {
          "stack": "2",
          "color": "#1a6f8b",
          "name": "2000",
          "text": "N. K. G. - Hemepol Rlomt"
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0002000445",
          "text": "TPILLIUN GEOLTG KOPE RPABUKTS IMK."
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0002000066",
          "text": "BSN JAAB SREKIOLTIES USO& IMK."
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0002000030",
          "text": "FAMES ROKCIMH IMK"
      },
      {
          "stack": "1",
          "color": "#4c1362",
          "name": "0001011649",
          "text": "RILLOP5 RGOPNO IMK"
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000001320",
          "text": "VOL-NOPT KOMOBO KAPR."
      },
      {
          "stack": "4",
          "color": "#9d591b",
          "name": "C-0000000256",
          "text": "LE HPAURE FEOM KAUTU (RFK) IMK"
      }
  ],
  "count": {
      "Consigment": {
          "color": "#fc96ee",
          "count": "0"
      },
      "Sub Contracting": {
          "color": "#bfea55",
          "count": "0"
      },
      "Direct Sales": {
          "color": "#fde560",
          "count": "5"
      },
      "External Processing": {
          "color": "#4b4545",
          "count": "5"
      },
      "Normal Purchasing": {
          "color": "#71afdb",
          "count": "5"
      },
      "Drop Ship": {
          "color": "#A52A2A",
          "count": "3"
      },
      "Intercompany DropShip": {
          "color": "#8B4513",
          "count": "10"
      },
      "STO": {
          "color": "#0f691b",
          "count": "3"
      }
  },
  "links": [
      {
          "targetname": "Fomssem Imk",
          "color": "#71afdb",
          "sourcename": "KILOH HNDG IMTEPMOTIAMOL (KOB) (765",
          "source": "0000650001",
          "text": "FINISHED GOODS - NORMAL PURCHASING",
          "value": "2.9377775333549805E9",
          "target": "1000"
      },
      {
          "targetname": "Fomssem Imk",
          "color": "#71afdb",
          "sourcename": "FOMSSEM RGOPN. DELHIUN (220) KOB",
          "source": "0000650006",
          "text": "FINISHED GOODS - NORMAL PURCHASING",
          "value": "5.2213888491015625E8",
          "target": "1000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#71afdb",
          "sourcename": "UN KOMOBO",
          "source": "0001017124",
          "text": "NORMAL PURCHASING",
          "value": "1.7119862201749754E8",
          "target": "4000"
      },
      {
          "targetname": "Fomssem Imk",
          "color": "#71afdb",
          "sourcename": "FOMSSEM Pz B IPELOMB",
          "source": "0002002835",
          "text": "FINISHED GOODS - NORMAL PURCHASING",
          "value": "1.42707975E8",
          "target": "1000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#71afdb",
          "sourcename": "NKMEIL OD (GELSIMHDAPH)",
          "source": "0000650262",
          "text": "FINISHED GOODS - NORMAL PURCHASING",
          "value": "8.77658111953125E7",
          "target": "4000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#A52A2A",
          "sourcename": "BSN JAAB SREKIOLTIES USO& IMK.",
          "source": "0002000066",
          "text": "FINISHED GOODS - DROPSHIP",
          "value": "1146326.40234375",
          "target": "4000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#4b4545",
          "sourcename": "TPILLIUN GEOLTG KOPE RPABUKTS IMK.",
          "source": "0002000445",
          "text": "EXTERNAL PROCESSING",
          "value": "2.228766506640625E7",
          "target": "4000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#4b4545",
          "sourcename": "FAMES ROKCIMH IMK",
          "source": "0002000030",
          "text": "EXTERNAL PROCESSING",
          "value": "2.123978507763672E7",
          "target": "4000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#4b4545",
          "sourcename": "RILLOP5 RGOPNO IMK",
          "source": "0001011649",
          "text": "EXTERNAL PROCESSING",
          "value": "1.759678073876953E7",
          "target": "4000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#4b4545",
          "sourcename": "MAWO ROKC LTB.",
          "source": "0002000450",
          "text": "EXTERNAL PROCESSING",
          "value": "1.1114775259480357E7",
          "target": "4000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#4b4545",
          "sourcename": "FUDILOMT GALLISTEPSTIEP HEMEPOL NOM",
          "source": "0001009128",
          "text": "EXTERNAL PROCESSING",
          "value": "1.1084785014404297E7",
          "target": "4000"
      },
      {
          "targetname": "NKCESSAM RGOPN. (DPON)",
          "color": "#fde560",
          "sourcename": "Fomssem Imk",
          "source": "1000",
          "text": "FINISHED GOODS - DIRECT SALES",
          "value": "7.89193887345779E8",
          "target": "C-0000001694"
      },
      {
          "targetname": "SGARREPS BPUH NOPT KAPR.",
          "color": "#fde560",
          "sourcename": "Fomssem Imk",
          "source": "1000",
          "text": "FINISHED GOODS - DIRECT SALES",
          "value": "4.999815494319136E8",
          "target": "C-0000000329"
      },
      {
          "targetname": "NKCESSAM RGOPN. (WKP)",
          "color": "#fde560",
          "sourcename": "Fomssem Imk",
          "source": "1000",
          "text": "FINISHED GOODS - DIRECT SALES",
          "value": "4.010948330976963E8",
          "target": "C-0000001370"
      },
      {
          "targetname": "NKCESSAM RGOPN. (NTL)",
          "color": "#fde560",
          "sourcename": "Fomssem Imk",
          "source": "1000",
          "text": "FINISHED GOODS - DIRECT SALES",
          "value": "3.650619773664564E8",
          "target": "C-0000000018"
      },
      {
          "targetname": "LE HPAURE FEOM KAUTU (RFK) IMK",
          "color": "#fde560",
          "sourcename": "Fomssem Imk",
          "source": "1000",
          "text": "FINISHED GOODS - DIRECT SALES",
          "value": "3.194059190036218E8",
          "target": "C-0000000256"
      },
      {
          "targetname": "ROPNOLOT KOMOBO",
          "color": "#A52A2A",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - DROPSHIP",
          "value": "3447720.0",
          "target": "C-0000001607"
      },
      {
          "targetname": "ROPNOLOT KOMOBO",
          "color": "#A52A2A",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - DROPSHIP",
          "value": "546360.0",
          "target": "C-0000001606"
      },
      {
          "targetname": "KK-5000",
          "color": "#8B4513",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "8.339293016738579E8",
          "target": "CC-5000"
      },
      {
          "targetname": "SGARREPS BPUH NOPT KAPR.",
          "color": "#8B4513",
          "sourcename": "KK-5000",
          "source": "CC-5000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "8.339293016738579E8",
          "target": "C-0000000329"
      },
      {
          "targetname": "KK-5000",
          "color": "#8B4513",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "5.0671239360990846E8",
          "target": "CC-5000"
      },
      {
          "targetname": "CAGL z JPISKG LTB (KAMKAPB)",
          "color": "#8B4513",
          "sourcename": "KK-5000",
          "source": "CC-5000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "5.0671239360990846E8",
          "target": "C-0000000309"
      },
      {
          "targetname": "KK-5000",
          "color": "#8B4513",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "3.018092953305E8",
          "target": "CC-5000"
      },
      {
          "targetname": "VOL-NOPT KOMOBO KAPR.",
          "color": "#8B4513",
          "sourcename": "KK-5000",
          "source": "CC-5000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "3.018092953305E8",
          "target": "C-0000001320"
      },
      {
          "targetname": "KK-5000",
          "color": "#8B4513",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "2.498506569946952E8",
          "target": "CC-5000"
      },
      {
          "targetname": "LE HPAURE FEOM KAUTU (RFK) IMK",
          "color": "#8B4513",
          "sourcename": "KK-5000",
          "source": "CC-5000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "2.498506569946952E8",
          "target": "C-0000000256"
      },
      {
          "targetname": "KK-5000",
          "color": "#8B4513",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "2.2209482115099478E8",
          "target": "CC-5000"
      },
      {
          "targetname": "LADLOVS IMK.",
          "color": "#8B4513",
          "sourcename": "KK-5000",
          "source": "CC-5000",
          "text": "FINISHED GOODS - INTERCOMPANY DROPSHIP",
          "value": "2.2209482115099478E8",
          "target": "C-0000001298"
      },
      {
          "targetname": "N. K. G. - Loktoib Em,yne amly",
          "color": "#0f691b",
          "sourcename": "Fagmsam z Fagmsam Imk.",
          "source": "4000",
          "text": "FINISHED GOODS - STO",
          "value": "1870182.2959756851",
          "target": "IP-2300"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#0f691b",
          "sourcename": "N. K. G. - Hemepol Rlomt",
          "source": "2000",
          "text": "FINISHED GOODS - STO",
          "value": "44653.80078125",
          "target": "IP-4000"
      },
      {
          "targetname": "Fagmsam z Fagmsam Imk.",
          "color": "#0f691b",
          "sourcename": "N. K. G. - Stodility Testimh",
          "source": "2100",
          "text": "FINISHED GOODS - STO",
          "value": "430.4599947929383",
          "target": "IP-4000"
      }
  ]
}