import React, { useEffect } from "react";
import { CaretRightOutlined } from '@ant-design/icons';
import { Button } from "antd";

const Accordian = ({ showContent, setShowContent }) => {
  
  const HandleShow =(e)=>{
    e.preventDefault();
    setShowContent(!showContent);
    return false
  } 


  return (
    <>
      <CaretRightOutlined onClick={(e)=>HandleShow(e)} rotate={showContent ? 90 : 0} />
    </>
  );
};

export default Accordian;
