import React, { useState, useCallback, useEffect } from "react";
import ReactFlow, { addEdge } from "reactflow";
import "reactflow/dist/style.css";
import "./flowchart.css";
import { Card } from "antd";
import { api_response } from "./flowchartData/nodeData";
import Popover from "../../atoms/popover/popover";
import InternalIcon from "../../../assets/home-icons/2.svg";
import ManufactureIcon from "../../../assets/home-icons/3.svg";
import DistributionIcon from "../../../assets/home-icons/4.svg";
import CustomerIcon from "../../../assets/home-icons/5.svg";
import { useDispatch, useSelector } from "react-redux";
import { loadState } from "../../../duck/reducers/commonReducer";
import { showLoader, hideLoader } from "../../../duck/actions/commonActions";
import { getCanadaL2Data } from "../../../duck/actions/commonActions";
import betaData from "./flowchartData/betaData.json";
import { CONFIG } from "../../../constants/config";
import { Columns } from "./flowchartData/column";

function FlowchartCard({
  setSelectedNode,
  setSelectedNodeVisiability,
  selectedSource,
  selectedtype,
  setSelectedEdgeVisiability,
}) {
  const dispatch = useDispatch();

  const { gete2emflowdata, gete2emflowdataLoadState } = useSelector(
    (state) => state.commonReducer
  );
  //console.log("gete2emflowdataLoadState",gete2emflowdata,gete2emflowdataLoadState);

  console.log("betaData", betaData, CONFIG);
  console.log("selectedtype", selectedtype);
  const [nodesDataAll, setNodesDataAll] = useState([]);
  const [nodesData, setNodesData] = useState([]);
  const [edges, setEdges] = useState([]);
  const [tooltip, setTooltip] = useState(null);
  const [isPopoverVisible, setPopoverVisibility] = useState(false);

  // Define constants for the grid layout
  const columnWidth = CONFIG?.Product == 'BETA' ? 450 : 300;
  const padding = 20;
  var nodeHeight = 50;

  // Calculate the x position based on the stack value
  function calculateXPosition(stack) {
    const column = parseInt(stack) - 1;
    return column * (columnWidth + padding);
  }

  // Create an array to store the number of nodes in each stack
  const nodesInStack = nodesData?.nodes?.reduce((acc, node) => {
    const stack = node.stack;
    acc[stack] = (acc[stack] || 0) + 1;
    return acc;
  }, {});

  // Calculate the y position based on the number of nodes in each stack
  function calculateYPosition(node) {
    const stack = node.stack;
    const indexInStack = nodesInStack[stack];
    const yPosition = indexInStack * (nodeHeight + padding);
    nodesInStack[stack]--;
    return yPosition;
  }

  const handleEdgeMouseEnter = (event, edge) => {
    // console.log("Edge ID",event,edge);
    const x = event.clientX + window.scrollX;
    const y = 20;
    setTooltip({
      text: (
        <div>
          <div>
            {edge?.source}-{edge?.sourceName}
          </div>
          <div>
            {edge?.target}-{edge?.targetName}
          </div>
          <div>imk {edge?.text}</div>
          {selectedtype === "netvalue" ? (
            <div>${edge?.stockwidth}</div>
          ) : selectedtype === "materialcount" ? (
            <div>{edge?.stockwidth}</div>
          ) : (
            <div>${edge?.stockwidth}</div>
          )}
        </div>
      ),
      x,
      y,
    });
    setPopoverVisibility(true);
  };

  const handleEdgeMouseLeave = () => {
    setPopoverVisibility(false);
  };

  const onNodeClick = (event, node) => {
    console.log("node", node);
    setSelectedNode(node);
    setSelectedNodeVisiability(true);
    setSelectedEdgeVisiability(false);

    let stack = parseInt(node.stack);
    const payload = {
      // varX: "3 C-0000001298 SAP",
      varX: `${stack - 1} ${node?.id} ${selectedSource}`,
    };
    dispatch(getCanadaL2Data(payload));
  };

  const calculateStrokeWidth = (value) => {
    console.log("value", value);

    const maxValue = nodesData?.links
      ?.map((item) => {
        return Number(item.value);
      })
      .reduce((acc, curr) => {
        return acc + curr;
      }, 0);

    const maxValueWhole = Math.round(maxValue);
    const valueEdge = Math.round(Number(value));
    const percent = Math.floor((valueEdge / maxValueWhole) * 100);
    console.log("percent", percent);

    let stockwidth;
    if (percent === 0) {
      stockwidth = 2;
    } else {
      stockwidth = Math.max(Math.ceil(percent / 2) * 4, 5);
    }

    return stockwidth;
  };

  const countIncomingEdges = (nodeId) => {
    // Filter edges where the target node matches the specified nodeId
    const incomingEdges = nodesData?.links?.filter(
      (edge) => edge.target === nodeId
    );
    // console.log("incomingEdges.length",incomingEdges)
    const quantity = incomingEdges
      ?.map((item) => {
        return calculateStrokeWidth(item.value);
      })
      .reduce((acc, curr) => acc + curr, 0);

    let nodeWidth = 0;

    switch (true) {
      case quantity <= 20:
        nodeWidth = 10;
        break;
      case quantity <= 40:
        nodeWidth = 20;
        break;
      case quantity <= 50:
        nodeWidth = 30;
        break;
      case quantity <= 70:
        nodeWidth = 40;
        break;
      case quantity <= 80:
        nodeWidth = 50;
        break;
      case quantity <= 100:
        nodeWidth = 60;
        break;
      default:
        nodeWidth = 5;

        break;
    }
    // console.log(quantity, "quantity")
    return nodeWidth;
  };

  // Create an array of objects with dynamic positions
  const dynamicNode = nodesData?.nodes?.map((node) => ({
    id: node?.name,
    data: {
      label: (
        <div>
          <p style={{ margin: "0px" }}>{node?.name}</p>
          <p
            style={{
              background: `${node?.color}`,
              margin: "0px",
              color: "white",
              height: `${countIncomingEdges(node?.name)}px`,
            }}
          ></p>
        </div>
      ),
    },
    position: {
      x: calculateXPosition(node.stack),
      y: calculateYPosition(node),
    },
    sourcePosition: "right",
    targetPosition: "left",
    incomingEdgesCount: countIncomingEdges(node?.name),
    stack: node?.stack,
    style: {
      border: `1px solid ${node?.color}`,
      padding: "0px",
      borderRadius: "5px",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      width: "100px",
      color: `${node?.color}`,
      fontWeight: "bold",
    },
  }));

  const dynamicEdge = edges?.map((edge, index) => {
    // Define a function to map edge.value to strokeWidth

    return {
      id: `${index + 1}`,
      source: edge?.source,
      target: edge?.target,
      targetName: edge?.targetname,
      sourceName: edge?.sourcename,
      text: edge?.text,
      value: edge?.value,
      stockwidth: Number(edge?.value),
      sourcestack: edge.stacksource,
      targetstack: edge.stacktarget, // pass the stack from ui
      style: {
        strokeWidth: calculateStrokeWidth(edge?.value), // Map value to strokeWidth
        stroke: edge.color,
      },
    };
  });

  const getStack = (edgeSource, gete2emflowdata) => {
    console.log("getstack calling", edgeSource, gete2emflowdata);

    const source =
      gete2emflowdata &&
      gete2emflowdata?.nodes?.filter((node) => node?.name === edgeSource);
    console.log("source", source);
    const stack = source
      ?.map((item) => {
        return item?.stack;
      })
      .join("");

    return stack;
  };

  const getE2EMFResponse = () => {
    if (gete2emflowdataLoadState === loadState?.STARTED) {
      dispatch(showLoader());
    } else if (gete2emflowdataLoadState === loadState?.SUCCESS) {
      const updateResponse = gete2emflowdata?.links?.map((edge) => {
        const stacksource = getStack(edge.source, gete2emflowdata);
        const stacktarget = getStack(edge.target, gete2emflowdata);

        console.log("Stack Source:", stacksource);
        console.log("Stack Target:", stacktarget);
        return {
          ...edge,
          stacksource,
          stacktarget,
        };
      });

      console.log("updateResponse", updateResponse);

      setNodesDataAll(gete2emflowdata);
      setNodesData(gete2emflowdata);
      setEdges(updateResponse);
      dispatch(hideLoader());
    } else if (gete2emflowdataLoadState === loadState?.FAILURE) {
      dispatch(hideLoader());
      alert("Error in fetching data");
    }
  };

  const BetaResponse = () => {
    try {
      dispatch(showLoader());
      setNodesDataAll(betaData);
      setNodesData(betaData);
      const updateResponse = betaData?.links?.map((edge) => {
        const stacksource = getStack(edge.source, gete2emflowdata);
        const stacktarget = getStack(edge.target, gete2emflowdata);

        console.log("Stack Source:", stacksource);
        console.log("Stack Target:", stacktarget);
        return {
          ...edge,
          stacksource,
          stacktarget,
        };
      });
      setEdges(updateResponse);
      dispatch(hideLoader());
    } catch (err) {}
  };

  const onEdgeClick = async (event, edge) => {
    let sourceStack = parseInt(edge.sourcestack);
    let targetStack = parseInt(edge.targetstack);
    console.log("edge.targetstack", sourceStack, targetStack, edge);

    const payload = {
      varX: `${sourceStack - 1} ${edge?.source} ,${targetStack - 1} ${
        edge?.target
      }, ${selectedSource}`,
    };
    setSelectedEdgeVisiability(true);
    setSelectedNodeVisiability(false);
    dispatch(getCanadaL2Data(payload));
  };

  useEffect(() => {
    if (CONFIG?.Product === "Spectra") {
      getE2EMFResponse();
    } else {
      BetaResponse();
    }
  }, [gete2emflowdataLoadState, CONFIG?.Product]);

  return (
    <>
      <div className="main-div">
        <Card
          title={
            <div className="title-main-div">
              {Columns?.map((item, index) => (
                <span key={index} className="card-span">
                  <img src={item?.icon} alt="" />{" "}
                  <h4 className="card-title-text">{item?.label}</h4>
                </span>
              ))}
            </div>
          }
          size="small"
          className="card"
          bodyStyle={{ paddingTop: "0px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0px 20px 0px 20px",
            }}
          >
            <div className="reactflow-div">
              <ReactFlow
                nodes={dynamicNode}
                edges={dynamicEdge}
                onEdgeMouseEnter={(e, edge) => handleEdgeMouseEnter(e, edge)}
                onEdgeMouseLeave={handleEdgeMouseLeave}
                onNodeClick={onNodeClick}
                onEdgeClick={onEdgeClick}
              ></ReactFlow>
              {isPopoverVisible && tooltip && <Popover tooltip={tooltip} />}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default FlowchartCard;
// console.log("nodes",nodes)
// console.log("nodes",links)
// const dynamicEdge = nodesData?.links?.map((edge, index) => ({
//   id: `${index + 1}`,
//   source: edge?.source,
//   target: edge?.target,
//   targetName: edge?.targetname,
//   sourceName: edge?.sourcename,
//   text: edge?.text,
//   value:edge?.value,
//   style: {
//     strokeWidth:,
//     stroke: edge.color,
//   },
// }));

// function roundoff(value){
//   return Math?.round(value)
// }
// const countIncomingEdges = (nodeId) => {
//   // Filter edges where the target node matches the specified nodeId
//   const incomingEdges = nodesData?.links?.filter(edge => edge.target === nodeId);
//   console.log("incomingEdges.length",incomingEdges)
//   const quantity = incomingEdges?.map((item)=>{
//      return Number(item.value)
//   }).reduce((acc,curr)=>acc+curr,0)

//   const overallQuantity = Math.round(quantity)
//   console.log(quantity, "quantity",overallQuantity)
//   return incomingEdges.length;

// };

// const getE2EMFCall = async () => {
//   try {
//     const payload = {
//       source: selectedSource,
//       mfviewtype: selectedtype,
//       // "serviceName": "getE2EMF"
//     };
//     dispatch(showLoader());
//     const res = await getE2EMFData(payload);
//     setNodesDataAll(res?.data);
//     setNodesData(res?.data);
//     dispatch(hideLoader());
//   } catch (error) {
//     alert(error.message);
//   }
// };

// useEffect(() => {
//   getE2EMFCall();
// }, [selectedSource, selectedtype]);

// if (edge && edge.sourcestack) {
//   setSourceStack(edge.sourcestack - 1); // Subtract 1 from the sourcestack value
// }

// if (edge && edge.targetstack) {
//   setTargetStack(edge.targetstack - 1); // Subtract 1 from the targetstack value
// }
// setSelectedSourceNode(edge?.source)
// setSelectedTargetNode(edge?.target)

// await getE2EMFCall()
// console.log("edge",edge)
// const getE2EMFCall = async () => {
//   try {
//     const payload = {
//     "varX": `${sourceStack} ${selectedSourceNode} ,${targetStack} ${selectedTargetNode}, ${selectedSource}`
//     };
//     dispatch(showLoader());
//     const res = await getCanadaLevel2Data(payload);
//         console.log('res', res);
//     dispatch(hideLoader());
//   } catch (error) {
//     alert(error.message);
//   }
// };
