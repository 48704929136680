import React, { useState } from "react";
import { Table } from "antd";
import CellModal from "../modal/modal";
import { useDispatch } from "react-redux";
import { getCanadaLevel3Data } from "../../../api/landing";
import { showLoader, hideLoader } from "../../../duck/actions/commonActions";
import "./listview.css";

const ListViewNode = ({ dataSource, keyName, type, source }) => {
  const dispatch = useDispatch();

  const [canada3data, setCanada3Data] = useState([]);
  const [ponumber,setPoNumber] = useState('')

  const renderClickableCell = (text, record, searchtype) => (
    <div onClick={() => handleCellClick(text, record, searchtype)}>{text}</div>
  );

  const getColumns = (keyName) => {
    switch (keyName) {
      case "level2Tier1NodeDetails":
      case "level2Tier2NodeDetails":
      case "level2Tier12LinkDetails":
        return [
          {
            title: "Purchase Order",
            dataIndex: "ebeln",
            sorter: (a, b) => a.ebeln - b.ebeln,
            render: (text, record) => renderClickableCell(text, record, "po"),
          },
          {
            title: "Vendor Name",
            dataIndex: "lname1",
            sorter: (a, b) => a.lname1 - b.lname1,
            //render: renderClickableCell,
          },
          {
            title: "Vendor ID",
            dataIndex: "lifnr",
            sorter: (a, b) => a.lifnr - b.lifnr,
            //render: renderClickableCell,
          },
          {
            title: "Plant",
            dataIndex: "werks",
            sorter: (a, b) => a.werks - b.werks,
            //render: renderClickableCell,
          },
          {
            title: "Plant Name",
            dataIndex: "wname1",
            sorter: (a, b) => a.wname1.localeCompare(b.wname1),
            //render: renderClickableCell,
          },
          {
            title: "Net Value",
            dataIndex: "netwr",
            sorter: (a, b) => a.netwr - b.netwr,
            //render: renderClickableCell,
          },
        ];
      case "level2Tier3CCNodeDetails":
      case "level2Tier23CCLinkDetails":
      case "level2Tier4NodeDetails":
      case "level2Tier24LinkDetails":
      case "level2Tier34LinkDetails":
        return [
          {
            title: "Sales Document",
            dataIndex: "vbap_vbeln",
            sorter: (a, b) => a.vbap_vbeln - b.vbap_vbeln,
            render: (text, record) => renderClickableCell(text, record, "so"),
          },
          {
            title: "Customer ID",
            dataIndex: "kunnr",
            sorter: (a, b) => a.kunnr - b.kunnr,
            //render: renderClickableCell,
          },
          {
            title: "Customer Name",
            dataIndex: "kname1",
            sorter: (a, b) => a.kname1 - b.kname1,
            //render: renderClickableCell,
          },
          {
            title: "Plant",
            dataIndex: "werks",
            sorter: (a, b) => a.werks - b.werks,
            //render: renderClickableCell,
          },
          {
            title: "Plant Name",
            dataIndex: "wname1",
            sorter: (a, b) => a.wname1 - b.wname1,
            //render: renderClickableCell,
          },
          {
            title: "Net Value",
            dataIndex: "sum_netwr",
            sorter: (a, b) => a.sum_netwr - b.sum_netwr,
            //render: renderClickableCell,
          },
        ];
      case "level2Tier3IPNodeDetails":
      case "level2Tier23IPLinkDetails":
        return [
          {
            title: "Material ID",
            dataIndex: "kunnr",
            sorter: (a, b) => a.kunnr - b.kunnr,
            //render: renderClickableCell,
          },
          {
            title: "Company Code",
            dataIndex: "sodocnum",
            sorter: (a, b) => a.sodocnum - b.sodocnum,
            //render: renderClickableCell,
          },

          {
            title: "Receiveing Plant",
            dataIndex: "receiving_plant_id",
            sorter: (a, b) => a.receiving_plant_id - b.receiving_plant_id,
            //render: renderClickableCell,
          },
          {
            title: "Receiveing Plant Name",
            dataIndex: "receiving_plant_name",
            sorter: (a, b) => a.receiving_plant_name - b.receiving_plant_name,
            //render: renderClickableCell,
          },
          {
            title: "sendingplant Plant",
            dataIndex: "sendingplant",
            sorter: (a, b) => a.sendingplant - b.sendingplant,
            //render: renderClickableCell,
          },
          {
            title: "sendingplantname Plant Name",
            dataIndex: "sendingplantname",
            sorter: (a, b) => a.sendingplantname - b.sendingplantname,
            //render: renderClickableCell,
          },

          {
            title: "Net Value",
            dataIndex: "sum_netwr",
            sorter: (a, b) => a.sum_netwr - b.sum_netwr,
            //render: renderClickableCell,
          },
        ];

      default:
        return "";
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = getColumns(keyName);
  console.log("column", columns);

  function findKeyWithData(data) {
    for (const key in data) {
      if (data[key].length > 0) {
        // setKeyName(key);
        console.log("key", key);
        return data[key];
      }
    }
  }

  const getCanada3Data = async (text, searchtype) => {
    try {
      const payload = {
        ordernumber: text,
        salesorderdetail: "",
        searchtype: searchtype,
        source: source,
      };
      dispatch(showLoader());
      const res = await getCanadaLevel3Data(payload);
      const data = findKeyWithData(res?.data)
      dispatch(hideLoader());
      console.log("res3", res?.data,data);
      console.log("poorso", text);
      setCanada3Data(data);
    } catch (error) {
      alert(error);
    }
  };

  const handleCellClick = async (orderno, record, searchtype) => {
    console.log("record", record, searchtype);
    setIsModalOpen(!isModalOpen);
    setPoNumber(orderno)
    await getCanada3Data(orderno, searchtype);
  };

  console.log("ponumber",ponumber)
  return (
    <div>
      <Table
        className="listview-table"
        columns={columns}
        dataSource={dataSource}
      />
      <CellModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        canada3data={canada3data}
        ponumber={ponumber}
      ></CellModal>
    </div>
  );
};

export default ListViewNode;
