import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import configureStore, { history } from "./duck/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Login from "../src/components/login/index"
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <>
    {/* <React.StrictMode> */}
    <Provider store={configureStore()}>
      {/* <ConnectedRouter history={history}> */}
      <Router history={history}>
        <App />
     
      </Router>
      {/* </ConnectedRouter> */}
    </Provider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
