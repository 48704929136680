import React, { useState,useRef } from "react";
import { Button, Modal, Row, Col, Divider } from "antd";
import PurchaseDetails from "./purchasedetails/purchasedetail";
import ItemDetails from "./itemdetails/itemdetails";
import GoodsMovement from "./goodsmovement/goodsmovement";
import printIcon from "../../../assets/home-icons/PrintIcon.png"
import "./modal.css";
import { useReactToPrint } from "react-to-print";
const CellModal = ({ isModalOpen, setIsModalOpen,canada3data,ponumber }) => {

  const componentRef = useRef();

  const [showGoods, setShowGoods] = useState(false);
  const [show, setShow] = useState("");
  const [display, setDisplay] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setShowGoods(false)
  };
 
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintFn = () => {
    if (show === "show") {
        setDisplay(true);
        setTimeout(() => {
            handlePrint();
        }, 1000);
    } else {
        handlePrint();
    }
};

 console.log("canada3dataClell",canada3data)
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <Row>
              <Col span={7} className="modal-title-div">
                <span className="order-span">Purchase Order: </span> <span className="type-span">{canada3data && canada3data.map((item)=>{
                      return item?.ekpo_ebeln
                })}</span>
              </Col>
              <Col span={13} className="modal-title-div">
              <span className="order-span">Purchase Order Type: </span> <span className="type-span">{canada3data && canada3data.map((item)=>{
                      return item?.poType
                })}</span>
              </Col>
              <Col style={{top:"-7px"}}>
                <div>
                  <Button className="save">Save</Button> <Button className="print"  onClick={handlePrintFn}><img src={printIcon} /></Button>
                </div>
              </Col>
            </Row>
          </div>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        width={"65%"}
        className="cellmodal"
      >
        <div style={{padding:"7px"}} ref={componentRef}>
          <PurchaseDetails canada3data={canada3data}/>
          <br/>
        <ItemDetails canada3data={canada3data}/>
        <br/>
        <GoodsMovement ponumber={ponumber} setShowGoods={setShowGoods} showGoods={showGoods}/>
        </div>
        
      </Modal>
    </>
  );
};
export default React.memo(CellModal);
