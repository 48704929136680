import React from "react";

const Popover = ({ tooltip }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: tooltip.y,
        left: tooltip.x,
        padding: "8px",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        color: "white",
        borderRadius: "4px",
        zIndex: 9999,
      }}
    >
      {/* Your popover content */}
      {tooltip.text}
    </div>
  );
};

export default React.memo(Popover);
